import './App.css';

function App({words}) {

  function getColor(str) {

    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color
  }

  let idx = Math.floor(Math.random()*words.length)
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{color: "#fff"}}>{words[idx]["word"]}</h1>
        <h2 style={{color: "rgba(255,255,255,0.5)"}}>{words[idx]["description"]}</h2>
        <svg height="400" width="500">
          <polygon points="250,60 100,400 400,400" fill={getColor(words[idx]["word"])} className="triangle" />
          <polygon points="250,60 100,400 200,400" fill={"rgba(255,255,255,0.3)"} className="triangle" />
        </svg>
        <h5>#{idx}</h5>
      </header>
    </div>
  );
}

export default App;
